import React from "react";
import PopUpImg from "../assets/images/pop-up-img1.jpg";
import PopUpImg2 from "../assets/images/pop-up-img2.jpg";

const Popup = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <img className="pop-up-img1" src={PopUpImg} alt="pop-up-img1" />
        <a className="close-btn popup-close" href="" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <p className="corpo">Qyaas Multi-brand Store</p>
        <p className="corpo-sub">Content Creation, Creative Ads, Branding</p>
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Our Designer Team</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Date:</p>
            <p className="cuberto">20/08/2024</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">Qyaas Multi-Brand Store</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Ad Creative</p>
          </div>
        </div>
        <p className="Ornare">
        Qyaas Multi-brand Store is a premier retail space that hosts a variety of high-end streetwear 
        and lifestyle brands. Our team was tasked with building a captivating visual identity and social 
        media strategy to help Qyaas enhance its online presence and reach a wider audience. The goal was to
         elevate brand awareness through engaging content that reflects the modern, stylish, and versatile 
         nature of the brands showcased at Qyaas.
        </p>
        <h3 class="text-light">Challenge</h3>
        <p className="Ornare">
        Our challenge was to design a cohesive branding strategy that not only captures the essence of 
        Qyaas but also resonates with its fashion-conscious clientele. This involved a deep understanding 
        of the target audience and the use of creative visuals that could easily stand out in a crowded 
        social media space.
        </p>
        <div className="pop-up-img-section2">
          <img src={PopUpImg2} alt="pop-up-img2" />
        </div>
        <h3 class="text-light">Conclusion</h3>
        <p className="Ornare p-0">
        Through our collaborative efforts, Qyaas Multi-brand Store now has a strong digital
         presence that mirrors the store's physical appeal. The combination of creative design, 
         targeted social media efforts, and cohesive branding has enabled Qyaas to position itself
          as a trendsetting retail space for streetwear enthusiasts.
        </p>
      </div>
    </div>
  );
};

export default Popup;
