import React, { useState, useEffect } from "react";
import ContentSvg from "../assets/images/svg/content-svg.svg";
import ContentImg from "../assets/images/content-img.png";
import NgleImg from "../assets/images/NGLE-Thumbnail.png";
import AWHImg from "../assets/images/AWH.png";
import AmazonImg from "../assets/images/Listings-Thumbnail.png"
import YoutubeVideoImg from "../assets/images/youtube-video-img.png";
import VimeoSvg from "../assets/images/svg/vimeo-svg.svg";
import SoundCloudImg from "../assets/images/sound-cloud-img.png";
import SoundCloudSvg from "../assets/images/svg/sound-cloud.svg";
import GalleryImg from "../assets/images/gallery-img.png";
import GallerySvg from "../assets/images/svg/gallery-svg.svg";
import Galleryimg1 from "../assets/images/gallery/gallery-img1.png";
import Galleryimg2 from "../assets/images/gallery/gallery-img2.png";
import Galleryimg3 from "../assets/images/gallery/gallery-img3.png";
import Galleryimg4 from "../assets/images/gallery/gallery-img4.png";
import Galleryimg5 from "../assets/images/gallery/gallery-img5.png";
import Galleryimg6 from "../assets/images/gallery/gallery-img6.png";
import Galleryimg7 from "../assets/images/gallery/gallery-img7.png";
import Galleryimg8 from "../assets/images/gallery/gallery-img8.png";
import Galleryimg9 from "../assets/images/gallery/gallery-img9.png";
import Galleryimg10 from "../assets/images/gallery/gallery-img10.png";
import Galleryimg11 from "../assets/images/gallery/gallery-img11.png";
import Galleryimg12 from "../assets/images/gallery/gallery-img12.png";
import Galleryimg13 from "../assets/images/gallery/gallery-img13.png";
import Galleryimg14 from "../assets/images/gallery/gallery-img14.png";
import Galleryimg15 from "../assets/images/gallery/gallery-img15.png";
import Popup from "./PopUp";
import Popup1 from "./PopUp-1";
import Popup2 from "./PopUp-2";
import Popup3 from "./PopUp-3";
import Popup4 from "./PopUp-4"; // Import Popup4
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopup1Visible, setIsPopup1Visible] = useState(false);
  const [isPopup2Visible, setIsPopup2Visible] = useState(false);
  const [isPopup3Visible, setIsPopup3Visible] = useState(false);
  const [isPopup4Visible, setIsPopup4Visible] = useState(false); // State for Popup4
  const [isVideoPopupVisible, setIsVideoPopupVisible] = useState(false);

  const handlePopupOpen = (e) => {
    e.preventDefault();
    setIsPopupVisible(true);
  };

  const handlePopupClose = (e) => {
    e.preventDefault();
    setIsPopupVisible(false);
  };

  // Function to open/close Popup1
  const handlePopup1Open = (e) => {
    e.preventDefault();
    setIsPopup1Visible(true);
  };

  const handlePopup1Close = (e) => {
    e.preventDefault();
    setIsPopup1Visible(false);
  };

  // Function to open/close Popup2
  const handlePopup2Open = (e) => {
    e.preventDefault();
    setIsPopup2Visible(true);
  };

  const handlePopup2Close = (e) => {
    e.preventDefault();
    setIsPopup2Visible(false);
  };

  // Function to open/close Popup3
  const handlePopup3Open = (e) => {
    e.preventDefault();
    setIsPopup3Visible(true);
  };

  const handlePopup3Close = (e) => {
    e.preventDefault();
    setIsPopup3Visible(false);
  };
  
  // Functions to toggle Popup4
  const handlePopup4Open = () => setIsPopup4Visible(true);
  const handlePopup4Close = () => setIsPopup4Visible(false);

// New Vimeo video popup functions
const handleVideoPopupOpen = () => {
  setIsVideoPopupVisible(true);
};

const handleVideoPopupClose = () => {
  setIsVideoPopupVisible(false);
};


  useEffect(() => {
    // Event listener to toggle 'full' class on image click
    const handleImageClick = (event) => {
      event.target.classList.toggle("full");
    };

    // Attach click event to gallery images
    const galleryLinks = document.querySelectorAll(".gallery-link img");

    if (galleryLinks && galleryLinks.length > 0) {
      galleryLinks.forEach((link) => {
        link.addEventListener("click", handleImageClick);
      });
    } else {
      console.error("No gallery images found");
    }

    // Cleanup function to remove event listeners
    return () => {
      if (galleryLinks && galleryLinks.length > 0) {
        galleryLinks.forEach((link) => {
          link.removeEventListener("click", handleImageClick);
        });
      }
    };
  }, []);

  return (
    <>
      <style jsx="true">
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-content {
            position: relative;
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            width: 70%;
            max-width: 800px;
          }

          .modal-close {
            position: absolute;
            top: 10px;
            right: 20px;
            background: none;
            border: none;
            color: black;
            font-size: 30px;
            cursor: pointer;
          }

          .image-container {
            position: relative;
            display: inline-block;
            cursor: pointer;
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          .image-container:hover .overlay {
            opacity: 1;
          }

          .overlay-text {
            color: white;
            font-size: 20px;
          }

          .vimeo-icon {
            width: 30px;
            height: 30px;
          }
        `}
      </style>

      <section className="portfolio-section" id="portfolio">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Portfolio.
          </h2>
          <div className="line"></div>
        </div>
        <div className="portfolios-group-main">
          <div>
            <div
              className="image-container popup-btn zoom_in"
              onClick={handlePopupOpen}
            >
              <img src={ContentImg} alt="content-img" />
              <div className="overlay">
                <img src={ContentSvg} alt="vimeo-svg" className="vimeo-icon" />
                <p className="overlay-text">Qyaas Multi-Brand Store</p>
              </div>
            </div>
            <p className="Corporate zoom_in">Qyaas Multi-Brand Store</p>
            <p className="Corporate-sub zoom_in">Qyaas Creatives</p>
            <div>
              <div
                className="second-row-portfolio mt-3"
                onClick={handlePopup4Open}
              >
                <div className="image-container">
                  <img src={AmazonImg} alt="listings-thumbnail" />
                  <div className="overlay">
                    <img
                      src={SoundCloudSvg}
                      alt="sound-cloud"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">Amazon Products</p>
                  </div>
                </div>
                <p className="Corporate">Amazaon Products Designing</p>
                <p className="Corporate-sub">Amazon Listing products</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className="image-container zoom_in"
                onClick={handlePopup1Open}
              >
                <img src={NgleImg} alt="Ngle-Thumbnail" />
                <div className="overlay">
                  <img
                    src={ContentSvg}
                    alt="vimeo-svg"
                    className="vimeo-icon"
                  />
                  <p className="overlay-text">Ningbo Green Light Energy</p>
                </div>
              </div>
            </div>
            <p className="Corporate zoom_in">Ningbo Green Light Energy</p>
            <p className="Corporate-sub zoom_in">Ningbo Creatives</p>
            <div>
              <div
                className="second-row-portfolio mt-3"
                onClick={handlePopup3Open}
              >
                <div className="image-container">
                  <img src={SoundCloudImg} alt="sound-cloud-img" />
                  <div className="overlay">
                    <img
                      src={SoundCloudSvg}
                      alt="sound-cloud"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">UI-Ux Designing</p>
                  </div>
                </div>
                <p className="Corporate">UI-UX Designing</p>
                <p className="Corporate-sub">Figma Design</p>
              </div>
            </div>
          </div>
          <div className="gallery-bank-section">
            <div
              className="second-row-portfolio zoom_in"
              onClick={handlePopup2Open}
            >
              <div className="image-container">
                <img src={AWHImg} alt="AWh-img" />
                <div className="overlay">
                  <img
                    src={ContentSvg}
                    alt="content-svg"
                    className="vimeo-icon"
                  />
                  <p className="overlay-text">AWH LLC</p>
                </div>
              </div>
              <p className="Corporate">Appointed WorldWide Holding</p>
              <p className="Corporate-sub">AWH Creatives</p>
            </div>

            <div className="zoom_in">
              <div className="image-container">
                <a href="#popup2">
                  <img src={GalleryImg} alt="gallery-img" />
                  <div className="overlay">
                    <img
                      src={GallerySvg}
                      alt="gallery-svg"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">Logo Designing</p>
                  </div>
                </a>
              </div>
              <p className="Corporate">Logo Designing</p>
              <p className="Corporate-sub">gallery</p>
            </div>
          </div>
        </div>
        <div>
          <h1 className="video-editing-heading">Video Editing</h1>
  
            {/* Video Editing Button */}
           <div className="video-thumbnail-container" onClick={handleVideoPopupOpen}>
              <img src={YoutubeVideoImg} alt="youtube-video-img" className="video-thumbnail" />
               <div className="overlay">
                 <img src={VimeoSvg} alt="vimeo-svg" className="vimeo-icon" />
                 <p className="overlay-text">Videos Editing</p>
                </div>
           </div>
        </div>

<style jsx="true">
  {`
    .video-editing-heading {
      text-align: center;
      margin-bottom: 20px;
    }

    .video-thumbnail-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
    }

    .video-thumbnail {
      width: 80%; /* Adjust the size as needed */
      height: 40%; /* Ensure it's a square to make it circular */
      border-radius: 5%; 
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: 8%; /* Ensures overlay matches circular shape */
    }

    .video-thumbnail-container:hover .overlay {
      opacity: 1;
    }

    .overlay-text {
      color: white;
      font-size: 18px;
    }

    .vimeo-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  `}
</style>

      </section>
      <Popup isVisible={isPopupVisible} onClose={handlePopupClose} />
      <Popup1 isVisible={isPopup1Visible} onClose={handlePopup1Close} />
      <Popup2 isVisible={isPopup2Visible} onClose={handlePopup2Close} />
      <Popup3 isVisible={isPopup3Visible} onClose={handlePopup3Close} />
      <Popup4 isVisible={isPopup4Visible} onClose={handlePopup4Close} />

      <div id="popup2" className="popup-container popup-style">
        <div className="popup-content popup-content-gallery">
          <a href="#pop" className="close">
            &times;
          </a>

          <main className="main">
            <h2 className="mobile_app">Logos Gallery</h2>

            <div className="container">
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg1}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg1} alt="gallery-img1" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg2}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg2} alt="gallery-img2" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg3}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg3} alt="gallery-img3" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg4}
                    data-fancybox="gallery"
                    data-caption="Caption Images 4"
                  >
                    <img src={Galleryimg4} alt="gallery-img4" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg5}
                    data-fancybox="gallery"
                    data-caption="Caption Images 5"
                  >
                    <img src={Galleryimg5} alt="gallery-img5" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg6}
                    data-fancybox="gallery"
                    data-caption="Caption Images 6"
                  >
                    <img src={Galleryimg6} alt="gallery-img6" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg7}
                    data-fancybox="gallery"
                    data-caption="Caption Images 7"
                  >
                    <img src={Galleryimg7} alt="gallery-img7" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg8}
                    data-fancybox="gallery"
                    data-caption="Caption Images 8"
                  >
                    <img src={Galleryimg8} alt="gallery-img8" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg9}
                    data-fancybox="gallery"
                    data-caption="Caption Images 9"
                  >
                    <img src={Galleryimg9} alt="gallery-img9" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg10}
                    data-fancybox="gallery"
                    data-caption="Caption Images 10"
                  >
                    <img src={Galleryimg10} alt="gallery-img10" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg11}
                    data-fancybox="gallery"
                    data-caption="Caption Images 11"
                  >
                    <img src={Galleryimg11} alt="gallery-img11" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg12}
                    data-fancybox="gallery"
                    data-caption="Caption Images 12"
                  >
                    <img src={Galleryimg12} alt="gallery-img12" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg13}
                    data-fancybox="gallery"
                    data-caption="Caption Images 13"
                  >
                    <img src={Galleryimg13} alt="gallery-img13" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg14}
                    data-fancybox="gallery"
                    data-caption="Caption Images 14"
                  >
                    <img src={Galleryimg14} alt="gallery-img14" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg15}
                    data-fancybox="gallery"
                    data-caption="Caption Images 15"
                  >
                    <img src={Galleryimg15} alt="gallery-img15" />
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* New Vimeo Video Popup */}
      {isVideoPopupVisible && (
        <div className="modal-overlay" onClick={handleVideoPopupClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={handleVideoPopupClose}>
              &times;
            </button>
            <iframe
              src="https://player.vimeo.com/video/1022588488?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Vimeo Video"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;
