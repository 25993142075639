import React, { useEffect } from "react";

const AboutSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">About Me.</h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">
        Hi, I'm a creative UI/UX & Graphics Designer with a passion for crafting visually captivating designs 
        that not only engage but also deliver results. With over a decade of experience, I specialize in transforming 
        ideas into intuitive and user-friendly digital interfaces that enhance user experiences.
         My design expertise extends beyond visuals, ensuring a seamless blend of aesthetics and functionality.

        In addition to my design proficiency, <br />I’m an Expert Full Stack Developer, building dynamic and 
        responsive websites using platforms like WordPress, Shopify, Subbly, Wix, and Squarespace. 
        I also develop powerful web applications utilizing technologies like MERN, PHP, and Laravel. 
        Beyond development, I offer video editing, SEO, social media management, and media buying services,
         including Meta and Google Ads.

        My commitment to blending creativity with technical prowess allows me to create impactful digital 
        experiences that align with your brand’s unique identity. Explore my portfolio to see how I bring 
        innovation to life with each project.
        </p>
        <div className="about-detail-main">
          <p className="about-detail">Name</p>
          <p className="about-detail-info">Shaheryar Ahmed</p>
        </div>
        <div className="about-detail-main">
  <p className="about-detail">Phone</p>
  <p
    className="about-detail-info email"
    onClick={() => window.location.href = 'tel:+12109035954'}
  >
    +1 210 903 5954
  </p>
</div>

<div className="about-detail-main">
  <p className="about-detail">Email</p>
  <p
    className="about-detail-info email"
    onClick={() => window.location.href = 'mailto:info@shaheryarahmed.com'}
  >
    info@shaheryarahmed.com
  </p>
</div>

        <div className="about-detail-main">
          <p className="about-detail">Experience</p>
          <p className="about-detail-info">6+ years</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Freelance</p>
          <p className="about-detail-info">Available</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Skype</p>
          <p className="about-detail-info">live:.cid.f2a421ae4a67a734</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Language</p>
          <p className="about-detail-info">English</p>
        </div>
      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
    </>
  );
};
export default AboutSection;
