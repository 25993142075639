import React from "react";
import PopUpImg3 from "../assets/images/Thumbnail-NGLE.webp";
import PopUpImg4 from "../assets/images/Thumbnail.NGLE-2.webp";

const Popup = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <img className="pop-up-img1" src={PopUpImg3} alt="thumbnail-NGLE" />
        <a className="close-btn popup-close" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <p className="corpo">Nignbo Green Light Energy</p>
        <p className="corpo-sub">Renewable Energy Solutions</p>
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Our Designer Team</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Date:</p>
            <p className="cuberto">05/9/2024</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">Ningbo Green Energy Light</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Ad Creative</p>
          </div>
        </div>
        <p className="Ornare">
        Ningbo Green Energy Light is a leading innovator in the renewable energy sector, providing top-tier 
        solar solutions for residential and commercial properties. Our team was engaged to enhance their digital 
        presence through a comprehensive rebranding and marketing strategy. The primary focus was to highlight 
        their cutting-edge solar products and services, making renewable energy more accessible and appealing to a broader audience.
        </p>
        <h3 class="text-light">Challenge</h3>
        <p className="Ornare">
        Ningbo Green Energy Light operates in a competitive market with numerous players in the renewable energy 
        industry. The challenge was to differentiate their offerings while ensuring 
        the branding reflects their expertise in green energy and their commitment to a sustainable future.
        </p>

        <div className="pop-up-img-section2">
          <img src={PopUpImg4} alt="Thumbnail.NGLE" />
        </div>
        <h3 class="text-light">Conclusion</h3>
        <p className="Ornare p-0">
        Ningbo Green Energy Light has solidified its presence in the renewable energy market thanks to a 
        cohesive branding strategy, a user-focused website, and targeted social media efforts. The results
         have not only boosted their business performance but have also contributed to increased awareness
          of the importance of adopting green energy solutions.
        </p>
      </div>
    </div>
  );
};

export default Popup;
