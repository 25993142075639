import React from "react";
import PopUpImg from "../assets/images/Amazone 1.jpg";
import PopUpImg2 from "../assets/images/Amazone 2.jpg";
import PopUpImg3 from "../assets/images/Amazone 3.png"; // Add new image
import PopUpImg4 from "../assets/images/Amazone 4.png"; // Add new image
import PopUpImg5 from "../assets/images/Amazone 5.png";
import PopUpImg6 from "../assets/images/Amazone 6.png";
import PopUpImg7 from "../assets/images/Amazone 7.png";
import PopUpImg8 from "../assets/images/Amazone 8.png";

const Popup4 = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <a className="close-btn popup-close" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>

        {/* Top Two Images */}
        <div className="pop-up-img-section2">
          <img className="popup-image" src={PopUpImg} alt="Amazone 1" />
          <img className="popup-image" src={PopUpImg2} alt="Amazone 2" />
        </div>
        <div className="pop-up-img-section2">
          <img className="popup-image" src={PopUpImg3} alt="Amazone 3" />
          <img className="popup-image" src={PopUpImg4} alt="Amazone 4" />
        </div>

        {/* Text Section */}
        <p className="corpo">Amazon Listing Product Designing Portfolio</p>
        <p className="corpo-sub">
          Engaging Product Listings, High-Impact Visuals, Optimized A+ Content
        </p>
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Our Design and Content Team</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Tool Used:</p>
            <p className="cuberto">Adobe Photoshop, Adobe Illustrator, Canva</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">
              Multiple Amazon Sellers, including top-rated brands in
              electronics, beauty, and home goods
            </p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Amazon Listing Design, E-commerce Visuals</p>
          </div>
        </div>

        <p className="Ornare">
          Our Amazon Listing Design process is dedicated to creating captivating
          and sales-driven product visuals that meet Amazon's guidelines and
          enhance the product's appeal. We understand that effective listings
          are not only visually compelling but also strategically designed to
          communicate the product's unique selling points (USPs) and convert
          browsers into buyers.
        </p>

        <h2 className="text-light">Design Approach</h2>
        <p className="Ornare">
          In every Amazon listing project, we focus on understanding the target
          audience and highlighting key product features through clear and
          attractive visuals. Our design process includes: <br></br>
          Product Research and Competitor Analysis<br></br>
          High-Quality Image Editing and Retouching<br></br>
          A+ Content Layout Design<br></br>
          SEO-optimized Titles and Descriptions<br></br>
        </p>

        {/* Bottom Two Images */}
        <div className="pop-up-img-section2">
          <img className="popup-image" src={PopUpImg5} alt="Amazone 5" />
          <img className="popup-image" src={PopUpImg6} alt="Amazone 6" />
        </div>
        <div className="pop-up-img-section2">
          <img className="popup-image" src={PopUpImg7} alt="Amazone 7" />
          <img className="popup-image" src={PopUpImg8} alt="Amazone 7" />
        </div>

        <h3 className="text-light">Conclusion</h3>
        <p className="Ornare p-0">
          Our Amazon Listing Design portfolio demonstrates a variety of
          approaches to enhance product visibility and appeal on Amazon. By
          combining eye-catching visuals, SEO-rich content, and strategic layout
          design, we help brands maximize their reach and sales potential on the
          platform. Our proficiency in tools like Adobe Photoshop, Illustrator,
          and Canva ensures each project is crafted to align with Amazon's best
          practices, resulting in optimized listings that boost conversions and
          brand credibility.
        </p>
      </div>

      <style jsx>{`
        .popup-wrap {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }

        .popup-wrap.visible {
          visibility: visible;
          opacity: 1;
        }

        .popup-box {
          background-color: #000;
          color: #fff;
          border-radius: 8px;
          padding: 20px;
          max-width: 900px;
          width: 100%;
          position: relative;
        }

        .popup-close {
          position: absolute;
          top: 10px;
          right: 20px;
          background: none;
          border: none;
          color: #fff;
          font-size: 30px;
          cursor: pointer;
        }

        .popup-image {
          width: 48%;
          margin: 1%;
          border-radius: 8px;
          height: auto;
        }

        .pop-up-img-section2 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .corporate_main {
          display: flex;
          flex-direction: column;
          align-items: flex-centre;
          margin-top: 20px;
        }

        .cuberto_main {
          display: flex;
          margin-bottom: 10px; /* Adds space between rows */
        }

        .created_by {
          font-weight: bold;
          color: #ccc;
          margin-right: 8px; /* Space between label and content */
        }

        .cuberto {
          font-size: 16px;
          margin-left: 20px;
          color: #fff;
        }

        .Ornare {
          font-size: 16px;
          line-height: 1.6;
          color: #fff;
          margin: 10px 0;
        }

        .text-light {
          font-size: 18px;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default Popup4;
