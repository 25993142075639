import React from "react";
import PopUpImg5 from "../assets/images/AWH Portfolio.png";
import PopUpImg6 from "../assets/images/AWH Portfolio 2.png";

const Popup = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <img className="pop-up-img1" src={PopUpImg5} alt="AWH Portfolio" />
        <a className="close-btn popup-close" href="#" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <p className="corpo">Appointed Worldwide Holdings (AWH LLC)</p>
        <p className="corpo-sub">Corporate Business Solutions</p>
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Our Designer Team</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Date:</p>
            <p className="cuberto">10/06/2024</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">AWH LLC</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Ad Creative, Corporate Solutions, Business Strategy, Financial Services</p>
          </div>
        </div>
        <p className="Ornare">
        Appointed Worldwide Holdings (AWH LLC) is a diversified financial services company offering corporate 
        business solutions tailored to global markets. Our team was tasked with revitalizing their digital 
        identity and creating a modern, user-centric platform that highlights their expertise across sectors 
        such as financial consulting, asset management, and strategic advisory services.
        </p>
        <h3 class="text-light">Challenge</h3>
        <p className="Ornare">
        AWH LLC required a digital transformation to match their evolving service offerings and market reach. 
        The challenge was to design a website and branding strategy that communicated their corporate 
        professionalism while simplifying complex financial concepts for their diverse audience, ranging 
        from startups to multinational corporations.
        </p>

        <div className="pop-up-img-section2">
          <img src={PopUpImg6} alt="AWH Portfolio 2" />
        </div>
        <h3 class="text-light">Conclusion</h3>
        <p className="Ornare p-0">
        By modernizing their digital presence and offering streamlined, accessible information on their
        services, AWH LLC has successfully expanded their client base and strengthened their position 
        in the global financial services market. Our collaboration helped them create a seamless and
         professional online experience, contributing to their continued growth and success.
        </p>
      </div>
    </div>
  );
};

export default Popup;
