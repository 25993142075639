import React, { useEffect, useRef } from "react";
import FigmaImg from "../assets/images/figma-img.png";
import PhotoShopImg from "../assets/images/photoshop-img.png";
import AdobeImg from "../assets/images/adobe-xd-img.png";
import SketchImg from "../assets/images/sktech-img.png";
import InvisionImg from "../assets/images/invision-img.png";
import WinnerAward from "../assets/images/winner-award.png";
import PremierePro from "../assets/images/Premiere-pro.png";
import MidJourney from "../assets/images/mid-journey.png";
import GoogleAds from "../assets/images/google-ads.png";
import MetaCertified from "../assets/images/meta-certified.png";

const Experience = () => {
  const colors = [
    "#BCE70C",
    "#FF759C",
    "#00CC97",
    "#FFDB59",
    "#6F39FD",
    "#FF7D61",
  ];
  const progressRef = useRef(null);
  const hasAnimated = useRef(false); // Track if the animation has already run

  useEffect(() => {
    const progressSection = progressRef.current;
    const items = progressSection.querySelectorAll(".progress-item");
    const observerOptions = { threshold: 0.1 };

    function handleIntersection(entries, observer) {
      if (entries[0].isIntersecting && !hasAnimated.current) {
        items.forEach((item, index) => {
          let num = parseInt(item.dataset.num);
          let count = 0;
          let color = colors[index % colors.length];
          let interval = setInterval(() => {
            if (count === num) {
              clearInterval(interval);
            } else {
              count++;
              item.style.background = `conic-gradient(${color} ${count}%, #80808047 0deg)`;
              item.setAttribute("data-value", `${count}%`);
              item.innerHTML = `${count}%`;
            }
          }, 15);
        });
        observer.unobserve(progressSection);
        hasAnimated.current = true; // Mark that the animation has run
      }
    }

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    observer.observe(progressSection);

    return () => observer.disconnect();
  }, [colors]);

  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="experience">
        <div className="row">
          {/* <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Education.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Master in Computer Science</p>
                <p className="cursus university">
                  Cambridge University / 2010 - 2014
                </p>
                <p className="cursus">
                  Eu nulla at mauris cursus consectetur posuere iaculis ipsum
                  neque. Morbi felis pellentesque ligula sed dictumst imperdiet
                  nunc vulputate.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Bachelor in Science</p>
                <p className="cursus university">
                  Cambridge University / 2014 - 2016
                </p>
                <p className="cursus">
                  Porttitor euismod at semper ut massa. Lorem varius magna
                  volutpat nunc. Et faucibus scelerisque donec augue eleifenda
                  morbi.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Diploma in Computer</p>
                <p className="cursus university">
                  Cambridge University / 2016 - 2018
                </p>
                <p className="cursus">
                  Adipiscing sed magna tempus arcu morbi. Ipsum pellentesque
                  lorem suscipit in. Hendrerit rhoncus quis tempor urna pharetra
                  ut erat.
                </p>
              </div>
            </div>
          </div> */}
          <div className="col-xxl-12 col-lg-12">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Experience.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">MEDIA BUYING SPECIALIST</p>
                <p className="cursus university">B My Marketer / 2022 - Present</p>
                <p className="cursus">
                Expert in developing strategic media buying campaigns across multiple platforms, 
                optimizing ad spend to achieve maximum ROI. Proven track record in audience targeting, 
                market analysis, and analytics reporting.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">GRAPHIC DESIGNER / UI-UX DESIGNER</p>
                <p className="cursus university">Clan Group of Companies / 2019 - 2022</p>
                <p className="cursus">
                Contributed to the design and development of user-centered interfaces for web and mobile applications.
                 Collaborated with cross-functional teams to enhance user experience, focusing on functionality and aesthetics.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">BRAND STRATEGIST</p>
                <p className="cursus university">Iplex Soft / 2016 - 2019</p>
                <p className="cursus">
                Developed and executed brand strategies to increase market presence and customer engagement.
                 Conducted comprehensive market research and competitive analysis, resulting in successful brand campaigns.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">VIDEO EDITOR</p>
                <p className="cursus university">Freelancer / 2018 - Present</p>
                <p className="cursus">
                Produced high-quality video content for promotional and advertising purposes. 
                Utilized advanced editing software to create engaging visuals that resonated with target audiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> 
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Coding Skill ===================================== --> */}
      <section className="coding-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Our Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div id="progress" ref={progressRef}>
          <div data-num="79" className="progress-item fade_up">
            Design
          </div>
          <div data-num="92" className="progress-item fade_up">
            sd
          </div>
          <div data-num="85" className="progress-item fade_up">
            sd
          </div>
          <div data-num="70" className="progress-item fade_up">
            sd
          </div>
          <div data-num="76" className="progress-item fade_up">
            ds
          </div>
          <div data-num="83" className="progress-item fade_up">
            ds
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section mt-5">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Design Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section col-xxl-12 col-xl-12 col-lg-12 col-md-6">
          <div className="design-skills-img-main flip_up">
            <img src={FigmaImg} alt="figma-img" />
            <div className="skill-counter-main">
              <p>94%</p>
              <p>FIGMA DESIGN</p>
            </div>
          </div>
          <div className="design-skills-img-main photoshop flip_up">
            <img src={PhotoShopImg} alt="photoshop-img" />
            <div className="skill-counter-main photoshop-text">
              <p>98%</p>
              <p>PHOTOSHOP</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={AdobeImg} alt="adobe-xd-img" />
            <div className="skill-counter-main adobe-xd-text">
              <p>88%</p>
              <p>ADOBE XD</p>
            </div>
          </div>

          <div className="design-skills-img-main sketch flip_up">
            <img src={SketchImg} alt="sktech-img" />
            <div className="skill-counter-main sketch-text">
              <p>85%</p>
              <p>SKETCH</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={InvisionImg} alt="invision-img" />
            <div className="skill-counter-main invision-text">
              <p>89%</p>
              <p>INVISION</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={MidJourney} alt="mid-journey" />
            <div className="skill-counter-main midjourney-text">
              <p>95%</p>
              <p>MIDJOURNEY</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Award ===================================== --> */}
      <section className="awards-section overflow-hidden">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Awards.
          </h2>
          <div className="line"></div>
        </div>
        <div className="row awards-row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward}
                        alt="winner-award4"
                      />
                      <p className="award-yer">2019</p>
                    </div>
                    <p className="award-interior">Adobe Certified</p>
                    <p className="award-winner-text">Professional</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    Achieved recognition as an Adobe Certified Professional, demonstrating expertise in Adobe software applications.
                     This certification highlights my skills in graphic design, UI/UX design, and media production.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={GoogleAds}
                        alt="google-ads"
                      />
                      <p className="award-yer">2019</p>
                    </div>
                    <p className="award-interior">Google Ads Certified</p>
                    <p className="award-winner-text">Professional</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    Achieved Google Ads Certification, demonstrating proficiency 
                    in online advertising strategies and campaign management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={MetaCertified}
                        alt="meta-certified"
                      />
                      <p className="award-yer">2018</p>
                    </div>
                    <p className="award-interior">Meta Certified</p>
                    <p className="award-winner-text">Professional</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    Recognized as Meta Certified, validating expertise in social media
                     marketing and campaign optimization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={PremierePro}
                        alt="premiere-pro"
                      />
                      <p className="award-yer">2018</p>
                    </div>
                    <p className="award-interior">PremierePro Certified</p>
                    <p className="award-winner-text">Professional</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    Achieved Premiere Pro Certification, demonstrating advanced video editing skills and creative storytelling expertise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Award End ===================================== --> */}
    </>
  );
};
export default Experience;
