import React from 'react';

const AmazonIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="none" viewBox="0 0 24 24">
        <path d="M3 3h18v18H3z" fill="#FF9900" />
        <path d="M7 12l3 3 7-7" stroke="#FFFFFF" strokeWidth="2" />
    </svg>
);

export default AmazonIcon;
