import React from "react";
import PopUpImg from "../assets/images/Figma design 1 (1).png";
import PopUpImg2 from "../assets/images/Figma design 1 (2).png";
import PopUpImg3 from "../assets/images/Figma design 1 (3).png"; // Add new image
import PopUpImg4 from "../assets/images/Figma design 1 (4).png"; // Add new image

const Popup = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <a className="close-btn popup-close" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>

        {/* Top Two Images */}
        <div className="pop-up-img-section2">
          <img className="popup-image" src={PopUpImg} alt="Figma design 1 (1).png"  />
          <img className="popup-image" src={PopUpImg2} alt="Figma design 1 (2).png"  />
        </div>

        {/* Text Section */}
        <p className="corpo">UI-Ux Designing Portfolio</p>
        <p className="corpo-sub">E-Commerce Mobile App Design, Healthcare Web App Redesign, Social Media Platform UI Redesign</p>
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Our Designer Team</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Tool Used</p>
            <p className="cuberto">Figma, Adobe XD, Sketch</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">LockChamp, Pro-lingo Master, Tv Services, Yoni Rescue</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Figma Design</p>
          </div>
        </div>

        <p className="Ornare">
        Our UI/UX design process is focused on creating user-centric, aesthetically pleasing, and highly 
        functional digital experiences. We believe that design is more than just visuals; it's 
        about how the user interacts with the interface and how it makes them feel.
        </p>

        <h3 className="text-light">Design Approach</h3>
        <p className="Ornare">
        In each project, we follow a human-centered design approach, ensuring that our UI/UX designs 
        are not only visually appealing but also solve real user problems. Our design process includes: Research and User Personas, Wireframes and Prototyping, User Testing, Final Design and Handoff.
        </p>

        {/* Bottom Two Images */}
        <div className="pop-up-img-section2">
          <img className="popup-image" src={PopUpImg3} alt="Figma design 1 (3).png"  />
          <img className="popup-image" src={PopUpImg4} alt="Figma design 1 (4).png"/>
        </div>

        <h3 className="text-light">Conclusion</h3>
        <p className="Ornare p-0">
        Our UI/UX design portfolio showcases a diverse range of projects across industries, from e-commerce to healthcare 
        and SaaS platforms. We focus on delivering user-centric designs that are both visually engaging and highly
         functional. By combining intuitive navigation, seamless user experiences, and modern aesthetics, we 
         help brands connect with their audience more effectively. Our expertise in tools like Figma, Sketch, 
         and Adobe XD ensures that each project is executed to perfection, delivering results that enhance user 
         engagement and drive business success.
        </p>
      </div>

      <style jsx>{`
        .popup-wrap {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8); /* Black background */
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }

        .popup-wrap.visible {
          visibility: visible;
          opacity: 1;
        }

        .popup-box {
          background-color: #000; /* Black background for the box */
          color: #fff; /* White text for readability */
          border-radius: 8px;
          padding: 20px;
          max-width: 900px;
          width: 100%;
          position: relative;
        }

        .popup-close {
          position: absolute;
          top: 10px;
          right: 20px;
          background: none;
          border: none;
          color: #fff; /* White close button */
          font-size: 30px;
          cursor: pointer;
        }

        .popup-image {
          width: 48%;
          margin: 1%;
          border-radius: 8px;
          height: auto;
        }

        .pop-up-img-section2 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

         .corporate_main {
           display: flex;
           justify-content: space-between;
           margin-top: 20px;
      }
    

         .cuberto_main {
           margin-right: 10px;
         }

        .Ornare {
          font-size: 16px;
          line-height: 1.6;
          color: #fff; /* White text */
          margin: 15px 0;
        }

        .text-light {
          font-size: 18px;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default Popup;

