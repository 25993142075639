import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com"; // Import emailjs for sending emails

const Contact = () => {
  const [inputState, setInputState] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });

  // Regex for name and email validation
  const namePattern = /^[A-Za-z\s]+$/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const inputs = document.querySelectorAll("input");

    inputs.forEach((input) => {
      input.addEventListener("focus", () => {
        if (input.parentElement) {
          input.parentElement.classList.add("active");
        }
      });

      input.addEventListener("focusout", () => {
        if (input.parentElement) {
          if (input.value === "") {
            input.parentElement.classList.remove("active");
          } else {
            input.parentElement.classList.add("active");
          }
        }
      });
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", () => {});
        input.removeEventListener("focusout", () => {});
      });
    };
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;

    // Validation logic for name and email fields
    if (id === "user_name" && !namePattern.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        user_name: "Name can only contain alphabets.",
      }));
    } else if (id === "user_email" && !emailPattern.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        user_email: "Please enter a valid email address.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    }

    setInputState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are valid
    if (
      !namePattern.test(inputState.user_name) ||
      !emailPattern.test(inputState.user_email) ||
      inputState.subject === "" ||
      inputState.message === ""
    ) {
      alert("Please fill all fields correctly.");
      return;
    }

    // Send the form data via email using EmailJS
    emailjs
      .sendForm(
        "service_on1617b", // EmailJS Service ID
        "template_wgu3cyh", // EmailJS Template ID
        e.target,
        "EHyfI3yJ1R__jYKJc" // Your EmailJS Public Key
      )
      .then(
        (result) => {
          alert("Message Sent Successfully!");
        },
        (error) => {
          alert("Failed to send message. Please try again.");
        }
      );

    // Reset form after submission
    setInputState({
      user_name: "",
      user_email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <section className="contact-section" id="contact">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up mt-5">
            Get In Touch.
          </h2>
          <div className="line"></div>
        </div>
        <p className="services-product-text fade_up">
          TAKE A COFFEE & CHAT WITH ME
        </p>
        <div className="main-box-contact">
          <div className="contact-box-main zoom_in">
            <div className="mobile-icon-main">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M6 6H42C43.1046 6 44 6.89544 44 8V40C44 41.1046 43.1046 42 42 42H6C4.89544 42 4 41.1046 4 40V8C4 6.89544 4.89544 6 6 6ZM24.1212 23.3658L11.2944 12.4754L8.70556 15.5246L24.1462 28.6342L39.3088 15.5123L36.6912 12.4877L24.1212 23.3658Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="contact-email-text">EMAIL</p>
              <p className="contact-email">
                <a href="mailto:info@shaheryarahmed.com">
                  info@shaheryarahmed.com
                </a>
              </p>
            </div>
          </div>
          <div className="contact-box-main zoom_in">
            <div className="mobile-icon-main">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M14 4H36C37.1046 4 38 4.89544 38 6V42C38 43.1046 37.1046 44 36 44H12C10.8954 44 10 43.1046 10 42V0H14V4ZM14 8V18H34V8H14Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="contact-email-text">PHONE</p>
              <p className="contact-email">
                <a href="tel:+12109035954">+1 210 903 5954</a>
              </p>
            </div>
          </div>
        </div>
        <div className="form-container">
          <form className="form" onSubmit={handleSubmit}>
            <div className="form">
              <label htmlFor="user_name" className="form__label">
                NAME
              </label>
              <input
                type="text"
                className="form__input"
                placeholder="Your name"
                id="user_name"
                name="user_name"
                required
                autoComplete="off"
                value={inputState.name}
                onChange={handleChange}
              />
              {errors.name && <p className="error-text">{errors.name}</p>}
            </div>
            <div className="form">
              <label htmlFor="user_email" className="form__label mt-2">
                EMAIL
              </label>
              <input
                type="email"
                className="form__input"
                placeholder="Your email"
                id="user_email"
                name="user_email"
                required
                autoComplete="off"
                value={inputState.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error-text">{errors.email}</p>}
            </div>
            <div className="form subject-input-main">
              <label htmlFor="subject" className="form__label">
                SUBJECT
              </label>
              <input
                type="text"
                className="form__input"
                placeholder="Your subject"
                id="subject"
                name="subject"
                required
                autoComplete="off"
                value={inputState.subject}
                onChange={handleChange}
              />
            </div>
            <div className="form">
              <label htmlFor="message" className="form__label">
                MESSAGE
              </label>
              <textarea
                className="form__input"
                placeholder="Write your message..."
                id="message"
                name="message"
                required
                autoComplete="off"
                value={inputState.message}
                onChange={handleChange}
                rows="2"
                style={{ resize: "none" }}
              />
            </div>
            <div className="wrapper blog-btn">
              <button className="btn-hover" type="submit">
                Submit Now
              </button>
            </div>
          </form>
        </div>
        <iframe
          className="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5933.195638320742!2d-87.664760718643!3d41.9659728462144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2327de28993%3A0xb1b5607dab541f0a!2s4553%20N%20Magnolia%20Ave%20APT%20202%2C%20Chicago%2C%20IL%2060640%2C%20USA!5e0!3m2!1sen!2s!4v1729542990929!5m2!1sen!2s"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="footer">
          © 2024 | All rights reserved by
          <span>
            <a className="text-light">Shaheryar Ahmed</a>
          </span>
        </div>
      </section>
    </>
  );
};

export default Contact;
